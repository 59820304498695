import {
  ShowAnswerWrapper,
  AnswerStyled,
  QuestionStyled,
  AnswerWrapper,
  AnswersWrapper,
  Dot,
  SolutionParagraph,
} from "./styled";
import { QuizAnswer, QuizQuestion } from "../../../store/types/survey";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";

type Props = {
  question: QuizQuestion;
};

export const ShowQuestionAnswer = ({ question }: Props) => {
  const { answers } = useSelector((s: StoreState) => s.survey);

  if (!answers) return null;

  const getColor = (answer: QuizAnswer) => {
    if (answer.correct) return "green";
    if (answers.includes(answer.id) && !answer.correct) {
      return "red";
    }
    return "default";
  };

  return (
    <ShowAnswerWrapper className="bg-pattern-black">
      <QuestionStyled>
        {`${question.order + 1}) ${question.text}`}
      </QuestionStyled>
      <AnswersWrapper>
        {question.answers.map((answer) => (
          <AnswerWrapper key={answer.id}>
            <Dot $selected={answers.includes(answer.id)} />
            <AnswerStyled $color={getColor(answer)}>{answer.text}</AnswerStyled>
          </AnswerWrapper>
        ))}
      </AnswersWrapper>
      <SolutionParagraph style={{ display: "none" }}>
        <b>Solution: </b>Glycine is the simplest amino acid. It contains a
        single hydrogen atom as its side chain. It is also a proteinogenic amino
        acid.
      </SolutionParagraph>
    </ShowAnswerWrapper>
  );
};
