import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StoreState } from "../../store/type";
import { fetchLessonInfo } from "../../store/thunks/Learner/fetchLessons";

import { ProgressBar } from "../../common/components/ProgressBar/ProgressBar";
import {
  PageWrapperStyled,
  LessonContentWrapper,
  StepsStyled,
  ErrorStyled,
} from "./LessonPageStyled";
import { Banner } from "./Banner/Banner";
import { VideoSection } from "./Video/Video";
import { Info } from "./Info/Info";
import { Spinner } from "../../common/components/Spinner/Spinner";
import { LessonCompleted } from "./LessonCompleted/LessonCompleted";

import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { markLessonCompleted } from "../../store/thunks/Learner/depository";
import { editDepository, removeLessons } from "../../store/slices/subject";
import { calculateProgress } from "../../common/utils/calculateLessonPoints";
import { Assistant } from "../Assistant/Assistant";
import { AllLessonsCompletedModal } from "./AllLessonsCompletedModal";

export const LessonPage = () => {
  const [loadingState, setLoadingState] = useState(true);

  const { requestInProgress } = useAsyncState(
    AsyncActions.FetchLessonInfoAction
  );

  const { error, requestInProgress: addDepositoryInProgress } = useAsyncState(
    AsyncActions.AddDepository
  );
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const { subjectCode, lessonCode } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lesson } = useSelector((state: StoreState) => state.subjects);

  const allLessonsCompleted = lesson?.depository?.allSubjectLessonsCompleted;

  const points = calculateProgress(lesson.depository);

  const closeSubjectCompleteModal = () => {
    dispatch(editDepository({ allSubjectLessonsCompleted: false }));
  };

  useEffect(() => {
    if (subjectCode && lessonCode && !addDepositoryInProgress) {
      dispatch(fetchLessonInfo({ subjectCode, lessonCode }));
    }
  }, [subjectCode, lessonCode, addDepositoryInProgress]);

  useEffect(() => {
    if ((!requestInProgress && lesson.id) || error) {
      return setLoadingState(false);
    }

    setLoadingState(true);
  }, [requestInProgress, lesson.id]);

  useEffect(() => {
    if (
      lesson.depository?.videoWatchedAt &&
      lesson.depository?.notesDownloadedAt &&
      lesson.depository?.solutionsDownloadedAt &&
      lesson.depository?.tasksDownloadedAt &&
      !lesson.depository?.completedAt
    ) {
      dispatch(markLessonCompleted(lesson.depository.id));

      setLessonCompleted(true);
    }
  }, [lesson.depository]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(removeLessons());
    };
  }, []);

  return (
    <PageWrapperStyled>
      {loadingState ? (
        <Spinner />
      ) : error ? (
        <ErrorStyled>{error}</ErrorStyled>
      ) : (
        <React.Fragment>
          <Banner />
          <LessonContentWrapper>
            <ProgressBar width={`${points}%`} />
            <StepsStyled>{t("lessonPage.steps")}</StepsStyled>
            <VideoSection depositoryId={lesson.depository?.id} />
            <Info />
          </LessonContentWrapper>
          {lessonCompleted && (
            <LessonCompleted modalOpen={setLessonCompleted} />
          )}
          {allLessonsCompleted && (
            <AllLessonsCompletedModal onClose={closeSubjectCompleteModal} />
          )}
        </React.Fragment>
      )}
      <Assistant />
    </PageWrapperStyled>
  );
};
