import Input, {
  LabelStyled,
  SpanStyled,
  InputStyled,
} from "../../../common/components/Input/Input";

import Button from "../../../common/components/Button/Button";
import {
  DetailsWrapper,
  DetailsSectionWrapper,
  HeadingStyled,
  InputWrapper,
  PromotionalCodeButton,
  PromotionalCodeWrapper,
  DetailsCardSectionWrapper,
  stripeClasses,
  stripeStyles,
  PaymentSpinner,
  PaymentRenewalInfo,
  ExternLink,
} from "./PaymentStyled";

import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from "react-stripe-elements";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  PaymentForm,
  PaymentFormFieldName,
} from "../../../common/types/PaymentForm";
import { paymentSchema } from "../../../views/validation/paymentValidationSchema";
import Form from "../../../common/components/Form/Form";
import { StyledError } from "../../../common/components/Error/Error";
import { usePayments } from "../../../store/hooks/usePayments";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { omit } from "ramda";
import { EstimatedBillingForm } from "../../../common/types/EstimatedBillingForm";
import { PaymentRequestStatus } from "../../../store/types/payment";
import { useTranslation } from "react-i18next";
import { env } from "../../../common/config/env";

type Props = {
  setCode: Dispatch<SetStateAction<string>>;
  subscriptions: EstimatedBillingForm;
  sm?: boolean;
  stripe: ReactStripeElements.StripeProps | null;
  elements: stripe.elements.Elements | null;
  renewal?: boolean;
};

const ByCard = ({
  sm,
  setCode,
  subscriptions,
  stripe,
  elements,
  renewal,
}: Props) => {
  const { firstName, lastName, username, address, postalCode, city } =
    useSelector((state: StoreState) => state.user.info);
  const {
    error,
    checkoutAttemptStatus,
    checkoutRenewalAttemptStatus,
    createSubscriptionStatus,
  } = useSelector((state: StoreState) => state.payments);
  const { error: estimatedBillingError } = useSelector(
    (state: StoreState) => state.payments.estimatedBilling
  );

  const { t } = useTranslation();
  const { formState, handleSubmit, register, getValues } = useForm<PaymentForm>(
    {
      mode: "onTouched",
      resolver: yupResolver(paymentSchema()),
      defaultValues: {
        name: firstName && lastName ? `${firstName} ${lastName}` : "",
        email: username ? username : "",
        address: address ? address : "",
        postalCode: postalCode ? postalCode : "",
        city: city ? city : "",
      },
    }
  );

  const requestInProgress =
    checkoutAttemptStatus === PaymentRequestStatus.Pending ||
    checkoutRenewalAttemptStatus === PaymentRequestStatus.Pending ||
    createSubscriptionStatus == PaymentRequestStatus.Pending;

  const {
    getPaymentMethods,
    emptyPaymentError,
    clearPaymentStatus,
    createSubscriptions,
    renewSubscription,
  } = usePayments(stripe, elements);

  useEffect(() => {
    getPaymentMethods();

    return () => {
      emptyPaymentError();
      clearPaymentStatus();
    };
  }, []);

  const onSubmit = (data: PaymentForm) => {
    try {
      gtag_report_conversion();
    } catch (e) {
      console.log(e);
    }
    if (renewal) {
      renewSubscription(data, subscriptions.map(omit(["learnerName"]))[0]);
      return;
    }
    createSubscriptions(data, subscriptions.map(omit(["learnerName"])));
  };

  const submitPaymentCode = () => {
    setCode(getValues("promoCode"));
  };

  if (!stripe || !elements) return null;

  return (
    <DetailsWrapper sm={sm}>
      <HeadingStyled>{t("register.payment.byCard.details")}</HeadingStyled>
      <DetailsCardSectionWrapper>
        <InputWrapper full>
          <LabelStyled>
            {t("register.payment.byCard.label.creditCardNumber")}
            <SpanStyled>*</SpanStyled>
          </LabelStyled>

          <CardNumberElement classes={stripeClasses} style={stripeStyles} />
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>
            {t("register.payment.byCard.label.validDuration")}
            <SpanStyled>*</SpanStyled>
          </LabelStyled>

          <CardExpiryElement classes={stripeClasses} style={stripeStyles} />
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>
            {t("register.payment.byCard.label.cvv")}
            <SpanStyled>*</SpanStyled>
          </LabelStyled>

          <CardCVCElement classes={stripeClasses} style={stripeStyles} />
        </InputWrapper>
      </DetailsCardSectionWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper mb="1.5rem" full>
          <Input
            type="text"
            inputWidth="100%"
            registerInput={register}
            placeholderText={t("register.payment.byCard.placeholders.name")}
            name={PaymentFormFieldName.NameOnCard}
            required={false}
            labelText={t("register.payment.byCard.label.nameOnCard")}
          />
          {formState.errors[PaymentFormFieldName.NameOnCard] ? (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors[PaymentFormFieldName.NameOnCard]?.message}
            </StyledError>
          ) : null}
        </InputWrapper>
        <HeadingStyled>
          {t("register.payment.byCard.billingDetails")}
        </HeadingStyled>
        <DetailsSectionWrapper>
          <InputWrapper full>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.billingName"
              )}
              inputWidth="100%"
              labelText={t("register.payment.byCard.placeholders.billingName")}
              required
              type="text"
              name={PaymentFormFieldName.Name}
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.Name] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.Name]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>
          <InputWrapper full>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.billingEmail"
              )}
              inputWidth="100%"
              labelText={t("register.payment.byCard.placeholders.billingEmail")}
              required
              type="text"
              name={PaymentFormFieldName.Email}
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.Email] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.Email]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>

          <InputWrapper>
            <Input
              placeholderText={t("register.payment.byCard.placeholders.city")}
              labelText={t("register.payment.byCard.placeholders.city")}
              required
              type="text"
              name={PaymentFormFieldName.City}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.City] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.City]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholderText={t("register.payment.byCard.placeholders.state")}
              labelText={t("register.payment.byCard.placeholders.state")}
              required={false}
              type="text"
              name={PaymentFormFieldName.State}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.State] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.State]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>

          <InputWrapper>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.country"
              )}
              labelText={t("register.payment.byCard.placeholders.country")}
              required
              type="text"
              name={PaymentFormFieldName.Country}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.Country] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.Country]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>

          <InputWrapper>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.postalCode"
              )}
              labelText={t("register.payment.byCard.placeholders.postalCode")}
              required
              type="text"
              name={PaymentFormFieldName.PostalCode}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.PostalCode] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.PostalCode]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>

          <InputWrapper full>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.address"
              )}
              labelText={t("register.payment.byCard.placeholders.address")}
              required
              type="text"
              name={PaymentFormFieldName.AddrLine1}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.AddrLine1] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.AddrLine1]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>

          <InputWrapper full>
            <Input
              placeholderText={t(
                "register.payment.byCard.placeholders.phoneNumber"
              )}
              labelText={t("register.payment.byCard.placeholders.phoneNumber")}
              required
              type="text"
              name={PaymentFormFieldName.PhoneNumber}
              inputWidth="100%"
              registerInput={register}
            />
            {formState.errors[PaymentFormFieldName.PhoneNumber] ? (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors[PaymentFormFieldName.PhoneNumber]?.message}
              </StyledError>
            ) : null}
          </InputWrapper>
        </DetailsSectionWrapper>
        <HeadingStyled>
          {t("register.payment.byCard.label.promoCode")}
        </HeadingStyled>
        <PromotionalCodeWrapper>
          <InputStyled
            placeholder={t("register.payment.byCard.placeholders.promoCode")}
            type="text"
            inputWidth="70%"
            {...register(PaymentFormFieldName.PromoCode)}
            name={PaymentFormFieldName.PromoCode}
            required={false}
          />
          <PromotionalCodeButton onClick={submitPaymentCode} type="button">
            {t("register.payment.byCard.buttons.applyCode")}
          </PromotionalCodeButton>
        </PromotionalCodeWrapper>
        {formState.errors[PaymentFormFieldName.PromoCode] ? (
          <StyledError bottomMargin="0" topMargin="0.441rem">
            {formState.errors[PaymentFormFieldName.PromoCode]?.message}
          </StyledError>
        ) : null}
        <Button
          type="submit"
          invert={false}
          buttonWidth="100%"
          bottomMargin="3.75rem"
          resPending={
            checkoutAttemptStatus === PaymentRequestStatus.Pending ||
            checkoutRenewalAttemptStatus === PaymentRequestStatus.Pending ||
            estimatedBillingError ||
            createSubscriptionStatus == PaymentRequestStatus.Pending
          }
        >
          {t("register.payment.byCard.buttons.pay")}
          {requestInProgress && <PaymentSpinner size={"tiny"} />}
        </Button>
        <PaymentRenewalInfo>
          {t("register.payment.byCard.renewalInfo")}{" "}
          <ExternLink
            className={"underline"}
            href={`mailto:${env.contactEmail}`}
            target="_blank"
            rel="noreferrer"
          >
            {env.contactEmail}
          </ExternLink>
        </PaymentRenewalInfo>
        {error ? (
          <StyledError topMargin="0" bottomMargin="0">
            {error}
          </StyledError>
        ) : null}
      </Form>
    </DetailsWrapper>
  );
};

export default injectStripe<{
  setCode: Dispatch<SetStateAction<string>>;
  subscriptions: EstimatedBillingForm;
  sm?: boolean;
  renewal?: boolean;
}>((props) => <ByCard {...props} />);
