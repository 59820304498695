import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../Tournament/modals/styled";
import subjectCompleteGif from "../../assets/gifs/Subject-Complete.gif";

import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import { TournamentModal } from "../Tournament/modals/TournamentModal";

type Props = {
  onClose: () => void;
};

export const AllLessonsCompletedModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const homeRoute = useUserHomeRoute();

  const goToHome = () => {
    navigate(`${homeRoute}`);
    onClose();
  };

  const title = t("lessonPage.allLessonsModal.title");

  const descriptions = [{ text: t("lessonPage.allLessonsModal.description") }];

  return (
    <TournamentModal
      onClose={onClose}
      title={title}
      descriptions={descriptions}
      icon={subjectCompleteGif}
    >
      <ActionButton onClick={goToHome}>
        {t("lessonPage.allLessonsModal.action")}
      </ActionButton>
    </TournamentModal>
  );
};
