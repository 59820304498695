import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "../../../common/components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { PopupCloseStyled } from "../../../common/components/Modal/ModalStyled";
import {
  BtnDiv,
  CupDiv,
  CupImage,
  ModalInnerWrapper,
  Operator,
  PointsWrapperStyled,
  PopupHeadingStyled,
  PopupParagraphStyled,
  QuizParagraph,
  TotalDiv,
  TotalHeading,
  TotalPoints,
  StyledLink,
  Button,
} from "./LessonCompletedStyled";
import { EarnedPoints } from "./EarnedPoints";
import cupIcon from "../../../assets/common/Lesson-Page/Cup.svg";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";
import { StoreState } from "../../../store/type";
import { removeSubjectState } from "../../../store/slices/subject";
import { addDepository } from "../../../store/thunks/Learner/lesson";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { fetchPoints } from "../../../store/thunks/Learner/fetchPoints";
import { StyledError } from "../../../common/components/Error/Error";
import { getRoute } from "../../../common/utils/getRoute";
import { calculateEarnedPoints } from "../../../common/utils/calculateLessonPoints";

export const LessonCompleted = ({
  modalOpen,
}: {
  modalOpen: (val: boolean) => void;
}) => {
  const { lesson } = useSelector((state: StoreState) => state.subjects);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const { requestInProgress: fetchPointsInProgress, error: fetchPointsError } =
    useAsyncState(AsyncActions.FetchPoints);

  const { totalPoints } = useSelector((state: StoreState) => state.user.info);

  const shouldShowQuizDialog =
    !!lesson?.quiz && !lesson.depository?.surveyDoneAt;

  const { total, video, tasks, solutions, notes } = calculateEarnedPoints(
    lesson.points,
    lesson.depository
  );

  const hasQuizPoints = typeof lesson.quizPoints === "number";
  const quizPoints = lesson.quizPoints || 0;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const closeModalHandler = () => {
    modalOpen(false);
  };

  const nextLessonHandler = () => {
    if (lesson.nextLessonCode && lesson.nextLessonId && lesson.subject?.code) {
      modalOpen(false);
      navigate(
        getRoute(
          {
            subjectCode: lesson.subject?.code,
            lessonCode: lesson.nextLessonCode,
          },
          RoutePath.lessonPage.locale[env.locale]
        )
      );

      dispatch(addDepository(lesson.nextLessonId));
      dispatch(removeSubjectState());
    }
  };

  useEffect(() => {
    if (lesson.depository?.completedAt) {
      dispatch(fetchPoints(lesson.id));
      setLoadingState(false);
    }
  }, [lesson.depository?.completedAt, lesson.id]);

  return (
    <Modal>
      <ModalInnerWrapper>
        {fetchPointsInProgress || loadingState ? (
          <Spinner size="sm" />
        ) : fetchPointsError ? (
          <StyledError centered topMargin="auto" bottomMargin="auto">
            {fetchPointsError}
          </StyledError>
        ) : (
          <Fragment>
            <PopupCloseStyled onClick={closeModalHandler}>
              &#10006;
            </PopupCloseStyled>
            <PopupHeadingStyled>
              {t("lessonPage.completedHeading")}
            </PopupHeadingStyled>
            <PopupParagraphStyled>
              {t("lessonPage.completedParagraph")}
            </PopupParagraphStyled>
            <PointsWrapperStyled hasQuizPoints={hasQuizPoints}>
              <EarnedPoints
                points={video}
                label={t("lessonPage.completedVideo")}
              />
              <Operator>+</Operator>
              <EarnedPoints
                points={notes}
                label={t("lessonPage.completedNotes")}
              />
              <Operator>+</Operator>
              <EarnedPoints
                points={tasks}
                label={t("lessonPage.completedQuestions")}
              />
              <Operator>+</Operator>
              <EarnedPoints
                points={solutions}
                label={t("lessonPage.completedAnswers")}
              />
              {hasQuizPoints && (
                <Fragment>
                  <Operator>+</Operator>
                  <EarnedPoints
                    points={quizPoints}
                    label={t("lessonPage.quizPoints")}
                  />
                </Fragment>
              )}
              <Operator>=</Operator>
              <EarnedPoints
                points={total + quizPoints}
                label={t("lessonPage.completedPointsEarned")}
                noMargin
              />
            </PointsWrapperStyled>
            <CupDiv>
              <CupImage src={cupIcon} />
              <TotalDiv>
                <TotalHeading>{t("lessonPage.completedTotal")}</TotalHeading>
                <TotalPoints>{totalPoints}</TotalPoints>
              </TotalDiv>
            </CupDiv>
            {shouldShowQuizDialog && (
              <QuizParagraph>{t("lessonPage.wantExtraPoints")}</QuizParagraph>
            )}
            <BtnDiv>
              <Button
                onClick={nextLessonHandler}
                resPending={!lesson.nextLessonCode || !lesson.nextLessonId}
                invert={shouldShowQuizDialog}
                full={!shouldShowQuizDialog}
              >
                {lesson.nextLessonCode && lesson.nextLessonId
                  ? t("lessonPage.completedNextLesson")
                  : t("lessonPage.completedNoMoreLessons")}
              </Button>
              {shouldShowQuizDialog && (
                <StyledLink
                  to={getRoute(
                    {
                      quizId: lesson.quiz?.id || "",
                    },
                    RoutePath.lessonPage.sub.survey.locale[env.locale]
                  )}
                >
                  <Button>{t("lessonPage.quiz")}</Button>
                </StyledLink>
              )}
            </BtnDiv>
          </Fragment>
        )}
      </ModalInnerWrapper>
    </Modal>
  );
};
