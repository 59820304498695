import { ReduxThunk } from "../../type";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { downloadAction, editDepository } from "../../slices/subject";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const downloadNotesAction: (
  depositoryId: string | undefined,
  font?: string | null
) => ReduxThunk = (depositoryId, font) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.DownloadNotes, value: true }));
    try {
      const response = await client.patch(
        `${ApiRoutes.Depository}/${depositoryId}`,
        {
          action: "DownloadNotes",
          font: font || "Helvetica",
        }
      );
      dispatch(
        downloadAction({
          key: AsyncActions.DownloadNotes,
          value: response.data.result,
        })
      );
      dispatch(editDepository({ notesDownloadedAt: new Date().toString() }));
    } catch (err: any) {
      dispatch(
        setErrorAction({ key: AsyncActions.DownloadNotes, value: "set" })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.DownloadNotes, value: false }));
  };
};

export const downloadQuestionsAction: (
  depositoryId: string | undefined,
  font?: string | null
) => ReduxThunk = (depositoryId, font) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.DownloadTasks, value: true }));
    try {
      const response = await client.patch(
        `${ApiRoutes.Depository}/${depositoryId}`,
        {
          action: "DownloadTasks",
          font: font || "Helvetica",
        }
      );
      dispatch(
        downloadAction({
          key: AsyncActions.DownloadTasks,
          value: response.data.result,
        })
      );
      dispatch(editDepository({ tasksDownloadedAt: new Date().toString() }));
    } catch (err: any) {
      dispatch(
        setErrorAction({ key: AsyncActions.DownloadTasks, value: "set" })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.DownloadTasks, value: false }));
  };
};

export const downloadAnswersAction: (
  depositoryId: string | undefined,
  font?: string | null
) => ReduxThunk = (depositoryId, font) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.DownloadSolutions, value: true })
    );
    try {
      const response = await client.patch(
        `${ApiRoutes.Depository}/${depositoryId}`,
        {
          action: "DownloadSolutions",
          font: font || "Helvetica",
        }
      );
      dispatch(
        downloadAction({
          key: AsyncActions.DownloadSolutions,
          value: response.data.result,
        })
      );
      dispatch(
        editDepository({ solutionsDownloadedAt: new Date().toString() })
      );
    } catch (err: any) {
      dispatch(
        setErrorAction({ key: AsyncActions.DownloadSolutions, value: "set" })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.DownloadSolutions, value: false })
    );
  };
};

export const markVideoWatched: (
  depositoryId: string | undefined
) => ReduxThunk = (depositoryId) => {
  return async (dispatch, _, { client }) => {
    try {
      await client.patch(`${ApiRoutes.Depository}/${depositoryId}`, {
        action: "MarkVideoAsWatched",
      });
      dispatch(editDepository({ videoWatchedAt: new Date().toString() }));
    } catch (err: any) {
      return;
    }
  };
};

export const addVideoWatchTime: (
  depositoryId: string | undefined,
  watchTime: number | undefined
) => ReduxThunk = (depositoryId, watchTime) => {
  return async (dispatch, _, { client }) => {
    try {
      await client.patch(`${ApiRoutes.Depository}/${depositoryId}`, {
        action: "AddVideoWatchTime",
        videoTime: watchTime,
      });
    } catch (err: any) {
      return;
    }
  };
};

export const markSurveyAsDone: (depositoryId: string) => ReduxThunk =
  (depositoryId) =>
  async (dispatch, _, { client }) => {
    try {
      await client.patch(`${ApiRoutes.Depository}/${depositoryId}`, {
        action: "MarkSurveyAsDone",
      });
    } catch {
      return;
    }
  };

export const markLessonCompleted: (
  depositoryId: string | undefined
) => ReduxThunk = (depositoryId) => {
  return async (dispatch, _, { client }) => {
    try {
      const { data } = await client.patch<{
        allSubjectLessonsCompleted?: boolean;
      }>(`${ApiRoutes.Depository}/${depositoryId}`, {
        action: "MarkAsDone",
      });

      dispatch(
        editDepository({
          completedAt: new Date().toString(),
          allSubjectLessonsCompleted: data.allSubjectLessonsCompleted,
        })
      );
    } catch (err: any) {
      return;
    }
  };
};
