import { FC } from "react";
import tw, { styled } from "twin.macro";

export const PageWrapper: FC = ({ children }) => {
  return (
    <div
      className={
        "min-h-app bg-pattern-black flex flex-col gap-8 items-center py-8 relative"
      }
    >
      {children}
    </div>
  );
};

export const Wrapper = styled.div(
  () =>
    tw`w-full min-h-app bg-white pt-6 md:pt-[6.25rem] flex flex-col gap-y-4 justify-between [*]:font-sans mb-2 md:mb-0 overflow-hidden`
);

export const ResultWrapper: FC = ({ children }) => {
  return (
    <div
      className={`w-full h-app bg-pattern-black flex flex-col items-center px-2 md:px-0`}
    >
      {children}
    </div>
  );
};

export const ErrorWrapper = styled.div(
  () => tw`pt-4 flex flex-col gap-3 items-center`
);

export const ErrorTitle = styled.h1(() => tw`font-sans text-lg`);
