import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Quiz } from "../../types/survey";
import { setQuizData } from "../../slices/survey";

export const fetchQuiz =
  (id: string, isTournamentQuiz = false): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ value: true, key: AsyncActions.FetchQuiz }));

    try {
      const res = await client.get<Quiz>(
        `${ApiRoutes.FetchQuiz}/${id}${isTournamentQuiz ? "/tournament" : ""}`
      );
      res.data.questions.sort((q1, q2) => q1.order - q2.order);
      dispatch(setQuizData(res.data));
    } catch (e) {
      setErrorAction({
        value: "Failed to fetch quiz",
        key: AsyncActions.FetchQuiz,
      });
    }

    dispatch(setBusyAction({ value: false, key: AsyncActions.FetchQuiz }));
  };
