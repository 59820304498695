import { FC } from "react";
import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../../common/components/Button/Button";

export const SurveyActionsWrapper = styled.div(
  () => tw`pl-6 md:pl-0 md:mx-auto flex md:gap-x-4 md:pb-8`
);

export const PageTitle = styled.h1(
  () => tw`text-lg font-sans text-black font-bold mb-4 font-sans`
);

export const TestResultWrapper = styled.div<{ showOnlyPoints: boolean }>(
  ({ showOnlyPoints }) => [
    tw`bg-yellow w-full md:w-[37.5rem] grid grid-rows-2 grid-cols-2 justify-center rounded-2xl md:flex md:items-center md:justify-around md:h-[5.5rem] mb-7`,
    showOnlyPoints && tw`flex items-center justify-around`,
  ]
);

export const ResultComponentWrapper = styled.div<{ hide: boolean }>(
  ({ hide }) => [
    tw`flex flex-col items-center md:block py-2 md:py-0`,
    hide && tw`hidden md:hidden`,
  ]
);

export const PaperIcon = styled.img(() => tw`mb-6 mt-20`);

export const IconTextWrapper = styled.div(
  () => tw`flex items-center justify-center`
);

export const Icon = styled.img(() => tw`mr-1`);

export const Text = styled.span(() => tw`text-lg font-bold`);

export const Label = styled.p(
  () => tw`text-sm text-black font-sans opacity-60`
);

export const ActionWrapper = styled.div(() => tw`flex gap-x-2`);

export const ResultSpinnerWrapper = styled.div(() => tw`h-[21.25rem]`);

export const SurveyResultPageWrapper: FC = ({ children }) => {
  return (
    <div
      className={`w-full h-app bg-pattern-black flex flex-col items-center px-2 md:px-0 py-16 font-sans text-black`}
    >
      {children}
    </div>
  );
};

export const Description = styled(Text)(
  () => tw`text-center font-sans font-medium`
);

export const ResultPendingTitle = styled(PageTitle)(() => tw`mt-16 text-black`);

export const ResultPendingSpinner = styled(ResultSpinnerWrapper)(
  () => tw`w-[21.25rem] relative`
);

export const QuestionWrapper = styled.div(() => [
  tw`w-full lg:w-[77%] md:pl-44 px-6`,
]);

export const QuestionsAnsweredLabel = styled.p(
  () => tw`text-base font-normal opacity-60 mb-3`
);

export const QuestionTitle = styled.h1(
  () => tw`text-3xl font-bold text-black-secondary mb-8`
);

export const AnswersWrapper = styled.div(
  () => tw`cursor-pointer flex flex-col gap-6 mb-6`
);

export const AnswerWrapper = styled.div<{ $highlight: boolean }>(
  ({ $highlight }) => [
    tw`bg-white border-gray text-base text-black border rounded-lg flex justify-between items-center pr-2.5 pl-4 min-h-[3rem]`,
    $highlight && tw`bg-light-green-secondary`,
  ]
);

export const AnswerLabel = styled.p(() => tw`opacity-70`);

export const RankWrapper = styled.div(
  () =>
    tw`bg-[#3F3F3F] rounded-full w-7 h-7 flex items-center justify-center text-white text-sm min-w-[1.5rem]`
);

export const QuestionActionsWrapper = styled.div(
  () =>
    tw`flex justify-start gap-y-2 gap-x-8 lg:gap-x-0 lg:justify-between w-[35rem] items-start md:items-center flex-col md:flex-row`
);

export const QuestionButtonsWrapper = styled.div(
  () => tw`flex flex-row md:gap-x-6 lg:gap-2`
);

export const SkipLabel = styled.p(
  () =>
    tw`text-base uppercase font-bold text-black-secondary opacity-60 cursor-pointer`
);

export const SurveyButton = styled(ButtonStyled)(() => tw`w-44`);
