import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Tournament } from "../../types/tournament";
import {
  setCurrentTournament,
  setHistoryTournaments,
  setUpcomingTournaments,
} from "../../slices/tournament";
import i18n from "../../../i18n";

export const getUpcomingTournaments: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.UpcomingTournaments, value: true })
    );

    try {
      const res = await client.get<Tournament[]>(ApiRoutes.UpcomingTournaments);

      dispatch(setUpcomingTournaments(res.data));
    } catch (e: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.UpcomingTournaments,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.UpcomingTournaments, value: false })
    );
  };

export const getRecentTournaments: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.RecentTournaments, value: true })
    );

    try {
      const res = await client.get<Tournament[]>(ApiRoutes.RecentTournaments);

      dispatch(setHistoryTournaments(res.data));
    } catch (e: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.UpcomingTournaments,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.RecentTournaments, value: false })
    );
  };

export const getTournament: (id: string) => ReduxThunk =
  (id) =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.FetchTournament, value: true }));

    try {
      const res = await client.get<Tournament>(`${ApiRoutes.Tournament}/${id}`);
      dispatch(setCurrentTournament(res.data));
    } catch (e) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchTournament,
          value: i18n.t("response.500"),
        })
      );
    }

    dispatch(
      setBusyAction({ key: AsyncActions.FetchTournament, value: false })
    );
  };
